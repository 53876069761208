var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c("span", { staticClass: "title" }, [_vm._v("课程系列管理")]),
      _c(
        "el-row",
        {
          staticClass: "row-bg",
          staticStyle: { width: "30%", "margin-top": "20px" },
        },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "has",
                  rawName: "v-has",
                  value: "curriculum:CategoryAdd",
                  expression: "'curriculum:CategoryAdd'",
                },
              ],
              staticClass: "tb-button",
              attrs: { type: "info", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.addCategory()
                },
              },
            },
            [_vm._v("新 增")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "dragTable",
          staticStyle: { width: "30%", "margin-bottom": "20px" },
          attrs: {
            data: _vm.cascaderList,
            "row-key": "id",
            border: "",
            "default-expand-all": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "courseGoodsName",
              label: "课程系列",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "has",
                            rawName: "v-has",
                            value: "curriculum:CategoryEdit",
                            expression: "'curriculum:CategoryEdit'",
                          },
                        ],
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.editCategory(scope.row)
                          },
                        },
                      },
                      [_vm._v("编 辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "has",
                            rawName: "v-has",
                            value: "curriculum:CategoryEdit",
                            expression: "'curriculum:CategoryEdit'",
                          },
                        ],
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.previewCategory(scope.row)
                          },
                        },
                      },
                      [_vm._v("查看课程")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("add-category", {
        attrs: {
          show: _vm.categoryDialog,
          editData: _vm.editData,
          cascaderList: _vm.cascaderList,
        },
        on: {
          close: function ($event) {
            _vm.categoryDialog = false
            _vm.editData = null
          },
          updateCourseCatalog: _vm.updateCourseCatalog,
        },
      }),
      _c("preview-category", {
        attrs: {
          show: _vm.previewCategoryDialog,
          courseGoodsId: _vm.courseGoodsId,
        },
        on: {
          close: function ($event) {
            _vm.previewCategoryDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }