<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>课程列表</span>
    </div>
    <span>拖动以下列表中的数据可以排序</span>
    <el-table
      :data="courseList"
      style="width: 30%;margin-bottom: 20px;margin-top: 15px"
      row-key="id"
      border
      ref="dragTable"
      default-expand-all
    >
      <el-table-column prop="title" label="课程名称" align="center"></el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
/**
 * 弹出框-使用封装
 * @attribute show 弹出框状态
 * @attribute editData 根据有无 editData数据 动态渲染 dialog弹出框 数据 和 弹框标题
 * @attribute cascaderList  el-cascader 级联选择器数据
 * @function close 自定义弹窗关闭事件
 */
import { getBindCourseList , reSortGroup} from '@/api/research/curriculum'
import Sortable from 'sortablejs'
export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    courseGoodsId: {
      default: '',
      type: String
    },
  },
  components: {
    Sortable
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  data () {
    return{
      courseList:[],
      courseListThemb:[],
      oldList:[],
      newList:[],
    }
  },
  methods: {
    setSort() {
      const el = this.$refs.dragTable.$el.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
      Sortable.create(el, {
        ghostClass: 'sortable-ghost',
        setData: (dataTransfer) => {
          dataTransfer.setData('Text', '')
        },
        onEnd: evt => {
          const targetRow = this.courseList.splice(evt.oldIndex, 1)[0]
          this.courseList.splice(evt.newIndex, 0, targetRow)
          const tempIndex = this.newList.splice(evt.oldIndex, 1)[0]
          this.newList.splice(evt.newIndex, 0, tempIndex)

          this.reSortGroup(this.courseListThemb[evt.oldIndex].id,this.courseListThemb[evt.newIndex].id)

          this.$forceUpdate()
        }
      })
    },
    async getBindCourseList () {
      const res = await getBindCourseList({
        courseGoodsId:this.courseGoodsId
      })
      this.courseList = res.body
      this.courseListThemb = []
      res.body.forEach((item)=> {
        this.courseListThemb.push(item)
      })

      this.oldList = this.courseList.map(v => v.id)
      this.newList = this.oldList.slice()
      this.$nextTick(() => {
        this.setSort()
      })
    },
    async reSortGroup (id,toId) {
      const res = await reSortGroup({
        id:id,
        toId:toId,
      })
      if (res && res.state === 'success') {
        window.$msg('排序成功')
        this.getBindCourseList()
      }
    },

    openDialog () {
      this.getBindCourseList()
    },

    // 关闭弹窗
    close () {
      this.$emit('close')
    },

  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
</style>
