<template>
  <el-row class="table">
    <span class="title">课程系列管理</span>
    <el-row class="row-bg" style="width: 30%;margin-top: 20px;">
      <el-button type="info" size="medium" @click="addCategory()" class="tb-button" v-has="'curriculum:CategoryAdd'">新 增</el-button>
    </el-row>
    <el-table
      :data="cascaderList"
      style="width: 30%;margin-bottom: 20px;"
      row-key="id"
      ref="dragTable"
      border
      default-expand-all
    >
      <el-table-column prop="courseGoodsName" label="课程系列" align="center"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="editCategory(scope.row)" v-has="'curriculum:CategoryEdit'">编 辑</el-button>
          <el-button type="text" @click="previewCategory(scope.row)" v-has="'curriculum:CategoryEdit'">查看课程</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 新增课程类别 -->
    <add-category
      :show="categoryDialog"
      @close="categoryDialog=false;editData=null"
      :editData="editData"
      :cascaderList="cascaderList"
      @updateCourseCatalog="updateCourseCatalog"
    ></add-category>
    <preview-category
      :show="previewCategoryDialog"
      @close="previewCategoryDialog=false;"
      :courseGoodsId = 'courseGoodsId'
    ></preview-category>
  </el-row>
</template>

<script>
import addCategory from '@/components/research/addCategory'
import previewCategory from '@/components/research/previewCategory'
import { courseCatalog , reSort} from '@/api/research/curriculum'
import Sortable from 'sortablejs'

export default {
  components: {
    addCategory,
    previewCategory,
    Sortable
  },

  data () {
    return {
      courseGoodsId:'',
      categoryDialog: false,
      previewCategoryDialog:false,
      editData: null,
      catalogId: '',
      cascaderList:[],
      cascaderListThemb:[],
    }
  },
  created() {
    this.courseCatalog()
  },
  methods: {
    setSort() {
      const el = this.$refs.dragTable.$el.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
      Sortable.create(el, {
        ghostClass: 'sortable-ghost',
        setData: (dataTransfer) => {
          dataTransfer.setData('Text', '')
        },
        onEnd: evt => {
          const targetRow = this.cascaderList.splice(evt.oldIndex, 1)[0]
          this.cascaderList.splice(evt.newIndex, 0, targetRow)
          const tempIndex = this.newList.splice(evt.oldIndex, 1)[0]
          this.newList.splice(evt.newIndex, 0, tempIndex)
          this.$forceUpdate()

          this.reSort(this.cascaderListThemb[evt.oldIndex].id,this.cascaderListThemb[evt.newIndex].id,)

        }
      })
    },
    // 添加课程类别
    addCategory () {
      this.categoryDialog = true
    },

    // 编辑
    editCategory (row) {
      this.editData = row
      this.categoryDialog = true
    },

    // 查看课程
    previewCategory (row) {
      this.previewCategoryDialog = true
      this.courseGoodsId = row.id
    },


    async courseCatalog () {
      const res = await courseCatalog({
        pageNum:1,
        pageSize:1000,
        needCount:true
      })
      this.cascaderList = res.body.list
      this.cascaderListThemb = []
      res.body.list.forEach((item)=> {
        this.cascaderListThemb.push(item)
      })
      this.oldList = this.cascaderList.map(v => v.id)
      this.newList = this.oldList.slice()
      this.$nextTick(() => {
        this.setSort()
      })
    },
    async reSort (id,toId) {
      const res = await reSort({
        id:id,
        toId:toId,
      })
      if (res && res.state === 'success') {
        window.$msg('排序成功')
        this.courseCatalog()
      }
    },
    // 操作完成,更新表格数据
    updateCourseCatalog () {
      this.courseCatalog()
    },

  }
}
</script>

<style scoped lang="scss">
@import "../../style/table.scss";
</style>
