var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", { domProps: { textContent: _vm._s(_vm.categoryTitle) } }),
        ]
      ),
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "课程系列名：", prop: "courseGoodsName" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入课程系列（不可重名）",
                  maxlength: "20",
                },
                model: {
                  value: _vm.formData.courseGoodsName,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "courseGoodsName", $$v)
                  },
                  expression: "formData.courseGoodsName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "课时单价：", prop: "originalPrice" } },
            [
              _c("el-input-number", {
                attrs: { placeholder: "请输入课时单价", maxlength: "20" },
                model: {
                  value: _vm.formData.originalPrice,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "originalPrice", $$v)
                  },
                  expression: "formData.originalPrice",
                },
              }),
              _c("span", { staticStyle: { "margin-left": "20px" } }, [
                _vm._v("元"),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "期课时量：", prop: "termAmount" } },
            [
              _c("el-input-number", {
                attrs: { placeholder: "请输入期课时量", maxlength: "20" },
                model: {
                  value: _vm.formData.termAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "termAmount", $$v)
                  },
                  expression: "formData.termAmount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "封面图片：" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "avatar-uploader",
                  attrs: {
                    action: _vm.uploadImageUrl,
                    "show-file-list": false,
                    "on-success": _vm.handleAvatarSuccess,
                    "before-upload": _vm.beforeAvatarUpload,
                  },
                },
                [
                  _vm.imageUrl
                    ? _c("img", {
                        staticClass: "avatar",
                        attrs: { src: _vm.imageUrl },
                      })
                    : _c("i", {
                        staticClass: "el-icon-plus avatar-uploader-icon",
                      }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.saveCategory()
                },
              },
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "info" },
              on: { click: _vm.close },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }